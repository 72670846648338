import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Bounce from 'react-reveal/Bounce'

class Service extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    let posts = data.allMarkdownRemark.edges.filter((edge) => {
      return edge.node.frontmatter.type === "service"
    })

    let x = 0

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All services" />
        <div className={"container"}>
          <div className={"row"}>
            <h3>Find out, what we are doing.</h3>

            <p className={"lead"}>
              We offer custom made websites and applications. This means, they're
              specially tailored for your needs. They will be fully customizable by you. So
              you won't need to call us every time you want to make changes to the content.
              It will be your website!
            </p>
          </div>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const direction = x % 2 === 0 ? "left" : "right"
            x++
            if(direction === "left") {
              return (
                <Bounce left>
                  <div className={"row"} key={node.fields.slug}>
                    <div className={"four columns"}>
                      <img src={node.frontmatter.main_image}
                        style={{backgroundSize: "100%", borderRadius: 10, boxShadow: `10px 10px 15px 0 rgba(50,50,50,0.5)`}}
                           alt={""}
                      />
                    </div>
                    <div className={"one column"} />
                    <div className={"seven columns"}>
                      <h5 style={{ textAlign: "center"}} className={"text-center"}>
                        <Link to={`service${node.fields.slug}`}>{title}</Link>
                      </h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </div>
                  </div>
                </Bounce>
              )
            } else {
              return (
                <Bounce right>
                  <div className={"row"} key={node.fields.slug}>
                    <div className={"seven columns"}>
                      <h5 style={{ textAlign: "center"}} className={"text-center"}>
                        <Link to={`service${node.fields.slug}`}>{title}</Link>
                      </h5>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </div>
                    <div className={"one column"} />
                    <div className={"four columns"}>
                      <img src={node.frontmatter.main_image}
                           style={{backgroundSize: "100%", borderRadius: 10, boxShadow: `10px 10px 15px 0 rgba(50,50,50,0.5)`}}
                           alt={""}
                      />
                    </div>
                  </div>
                </Bounce>
              )
            }

          })}
        </div>
        <Link to="/">
          <Button marginTop="85px">Go Home</Button>
        </Link>
      </Layout>
    )
  }
}

export default Service

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
            type
            main_image
          }
        }
      }
    }
  }
`
